import React from 'react';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import PrevIcon from '@material-ui/icons/NavigateBefore';
import NextIcon from '@material-ui/icons/NavigateNext';
import Button from '@material-ui/core/Button';
import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn';
import deepOrange from '@material-ui/core/colors/deepOrange';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import QuestionTextTitle from './QuestionTextTitle';

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
  },
  card: {
    minWidth: 275,
  },
  title: {
    marginBottom: 16,
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  buttonDiv: {
    display: 'flex',
    justifycontent: 'flex-start',
  },
  button: {
    margin: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    color: theme.palette.text.secondary,
  },
  textField: {
    maxWidth: 20
  }
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#357a38',
    },
    secondary: deepOrange,
  },
  typography: {
    useNextVariants: true,
  },
});

const Question = ({ classes, question, runningTime, answer, count, handlePrev, handleNext, handleAnswerChange, submitAnswers }) => {
  const min = parseInt(runningTime/60);
  const sec = parseInt(runningTime%60);
  const timer = (min < 10 ? '0'+min : min) + ':' + (sec < 10 ? '0'+sec : sec);
  return (
    <div className={classes.root}>
    <Grid container direction="row" justify="center" alignItems="center" spacing={24}>
      <Grid item xs={11}>
        <Paper className={classes.paper}>
          {!question && <CircularProgress className={classes.progress} />}
          {question &&
          <div className={classes.buttonDiv}>
            <MuiThemeProvider theme={theme}>
              <Button variant="contained" color="secondary"
                              className={classes.button}
                              onClick={handlePrev}
                              disabled={question.number===1}>
                <PrevIcon className={classes.leftIcon}></PrevIcon>
                Previous
              </Button>
            </MuiThemeProvider>
            <Button variant="contained" color="primary"
                              className={classes.button}
                              onClick={handleNext}
                              disabled={question.number===count}
                              >
              Next
              <NextIcon className={classes.rightIcon}></NextIcon>
            </Button>
            <div className={classes.grow} />
            <MuiThemeProvider theme={theme}>
              <Button variant="contained" color="primary"
                              className={classes.button}
                              onClick={submitAnswers}
                              >
                  Check Answers: {timer} -
                  <AssignmentTurnedIn className={classes.rightIcon}></AssignmentTurnedIn>
                </Button>
              </MuiThemeProvider>

          </div>
          }

        </Paper>
      </Grid>
      <Grid item xs={11}>
        <Paper className={classes.paper}>
        {question &&
          <div>
          <Typography className={classes.title} color="textSecondary">
            Question No: {question.number} of {count}
          </Typography>
          <QuestionTextTitle>
            {question.text}
          </QuestionTextTitle>
          <TextField key={"textField"}
            autoFocus
            id={"outlined-with-placeholder"+question.number}
            label="Answer"
            placeholder="Answer"
            className={classes.textField}
            margin="normal"
            variant="outlined"
            autoComplete='off'
            value={answer}
            onChange={handleAnswerChange(question.number-1)}
          />

          </div>
        }
        </Paper>
      </Grid>

    </Grid>
    </div>
  );
}

Question.propTypes = {
  classes: PropTypes.object.isRequired,
  question: PropTypes.object,
};

export default withStyles(styles)(Question);
