import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import searchImg from '../static/images/search-img.png';
import historyImg from '../static/images/test-history.png';
import Grid from '@material-ui/core/Grid';
import Title from './Title';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    color: theme.palette.text.secondary,
  },
  centerTypo: {
    textAlign: 'center',
  },
  demo: {
   backgroundColor: theme.palette.background.paper,
 },
});

const AboutCookie = ({ classes }) => {
    return(
      <div className={classes.root}>
        <Paper className={classes.paper} elevation={1}>
          <Title>
            Details about cookies on elevenplustimes.co.uk
          </Title>
          <p>elevenplustimes.co.uk puts small files (known as ‘cookies’) onto your computer to collect information about how you browse the site and to remember your login.</p>

          <p>Cookies are used to:</p>

          <ul>
            <li>measure how you use the website so it can be updated and improved based on your needs</li>
            <li>remember the notifications you’ve seen so we do not show them to you again</li>
            <li>remember you while you are using the website</li>
          </ul>

          <p>Find out more about <a href="https://ico.org.uk/for-the-public/online/cookies/" target="_blank">how to manage cookies</a>.</p>

          <Typography variant='h6'>
            How cookies are used on elevenplustimes.co.uk
          </Typography>
          <h3>Introductory message</h3>

          <p>You may see a pop-up message about the cookie consent when you visit elevenplustimes.co.uk. We’ll store a cookie so that your computer knows you’ve agreed to it and knows not to show it again.</p>

          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Purpose</th>
                <th>Expires</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>CookieConsent</td>
                <td>Stores your consent to cookies</td>
                <td>1 year</td>
              </tr>
            </tbody>
          </table>

          <h3>Login</h3>

          <p>We use third party system (i.e Google) to sign you in our website. We do not have any control over the cookies created by the third party system as part of the login. These cookies are likely required to enable you to log into secure areas of our Website. </p>
          <p>You can block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our Website.</p>
        </Paper>
      </div>
    );
};

export default withStyles(styles)(AboutCookie);
