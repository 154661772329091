import React from 'react';
import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer, Tooltip } from 'recharts';
import Title from './Title';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';

// Generate Sales Data
function createData(day, percentage) {
  return { day, percentage };
}

const data = [
  createData('Day 1', 50),
  createData('Day 2', 62),
  createData('Day 3', 68),
  createData('Day 4', 78),
  createData('Day 5', 89),
  createData('Day 6', 80),
  createData('Day 7', 94),
];

const useStyles = makeStyles(theme => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function HomepageProgressChart() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Title>Sample Progress Chart</Title>
        <LineChart
          data={data}
          width={500}
          height={230}
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 24,
          }}
        >
          <XAxis dataKey="day" />
          <YAxis>
            <Label angle={270} position="left" style={{ textAnchor: 'middle' }}>
              Percentage (%)
            </Label>
          </YAxis>
          <Tooltip />
          <Line type="monotone" dataKey="percentage" stroke="#556CD6" activeDot={{ r: 8 }} />
        </LineChart>
        <div className={classes.seeMore}>
          <Link color="primary" href="/progress">
            View your progress charts
          </Link>
        </div>
    </React.Fragment>
  );
}
