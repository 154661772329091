import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { mainListItems, homeworkListItems, linkListItems } from './listItems';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Login from './Login';
import LoginLanding from './LoginLanding';
import logoImg from '../static/images/logo3.png';
import Button from '@material-ui/core/Button';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { SessionBaseUrl } from './Constant';
import { withRouter } from 'react-router-dom';
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';



const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    color: theme.palette.text.secondary,
  },
  popover: {
    pointerEvents: 'none',
  },
});

function MadeWithLove() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      <Link color="inherit" href="https://material-ui.com/">
        Cookie Policy
      </Link>
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  subMenu: {
    color: 'green',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  title: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

class SiteTemplateBasic extends React.Component {
  constructor() {
    super();
    this.state = {
        login: false,
        authenticated: false,
        user: null,
        menu: 'search',
        anchorEl: null,
    };
  }

  componentDidMount() {
      const sessionId = sessionStorage.getItem('sessionId');
      // console.log('did mount');
      if (sessionId) {

        const tokenBlob = new Blob([JSON.stringify({sessionId: sessionStorage.getItem('sessionId')}, null, 2)], {type : 'application/json'});
                const options = {
                    method: 'POST',
                    body: tokenBlob,
                    mode: 'cors',
                    cache: 'default'
                };
                fetch(`${SessionBaseUrl}/session`, options).then(r => {
                    r.json().then(user => {
                        if (user.email) {
                            this.loginSuccess(user);
                        }
                    });
                })
      }
  }

  handleLoginButton = () => {
    this.setState({login: true});
  }

  loginSuccess = (user) => {
    this.setState({login: false, authenticated: true, user: user});
  }

  loginFailed = () => {
    console.log("login failed");
    this.setState({login: false, authenticated: false, user: null});
    sessionStorage.removeItem('sessionId');
  }

  handleMenuButtonChange = (event, newValue) => {
    this.setState({menu: newValue, login: false});

    if (newValue === 'home') {
      this.props.history.push('/');
    } else {
      this.props.history.push('/'+newValue);
    }
  }

  handleMenu = event => {
    this.setState({anchorEl: event.currentTarget});
  };

  handleClose = () => {
    this.setState({anchorEl: null});
  };

  handleLogout = () => {
    this.setState({anchorEl: null, authenticated: false, token: '', user:    null})
    sessionStorage.removeItem('sessionId');
    window.location.reload();
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
          <CssBaseline />
          <AppBar position="sticky"
            className={clsx(classes.appBar)}>
            <Toolbar>
              <Grid container direction="row" justify="center" alignItems="center">
                <Grid item xs={3}>
                    <a href="/"><img src={logoImg} /></a>
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                    </Typography>
                </Grid>
                <Grid item xs={8} justifycontent='center'>
                  <Tabs centered value={this.state.menu} onChange={this.handleMenuButtonChange}>
                          <Tab value = "search" label="Worksheet" />
                          <Tab value = "history" label="History" />
                          <Tab value = "charts" label="Charts" />
                  </Tabs>
                </Grid>
                <Grid item xs={1} justifycontent='right'>
                  {
                    this.state.authenticated
                    ?
                      <div>
                        <Tooltip title={this.state.user.email}>
                          <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={this.handleMenu}
                            color="inherit">
                            <AccountCircle />
                          </IconButton>
                        </Tooltip>

                        <Menu
                          id="menu-appbar"
                          anchorEl={this.state.anchorEl}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          keepMounted
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          open={Boolean(this.state.anchorEl)}
                          onClose={this.handleClose}
                        >
                          <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
                        </Menu>
                      </div>
                    :
                      <Button color="inherit" onClick={this.handleLoginButton}>Login</Button>
                  }
                </Grid>

              </Grid>
            </Toolbar>
          </AppBar>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="xl" className={classes.container}>
            {
              this.state.login
              ?
                <LoginLanding
                loginSuccess = {this.loginSuccess}
                loginFailed = {this.loginFailed}/>
              :
                this.props.children

            }
            </Container>
          </main>
      </div>
    );
  }
}

SiteTemplateBasic.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(SiteTemplateBasic));
