import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import deepOrange from '@material-ui/core/colors/deepOrange';
import indigo from '@material-ui/core/colors/indigo';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { GetQuestionBaseUrl, CreateQuestionBaseUrl, DownloadPdfBaseUrl, BaseUrl, Types } from './Constant';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';


const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifycontent: 'center',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    minWidth: 300,
  },
  buttonDiv: {
    display: 'flex',
    justifycontent: 'center',
  },
  button: {
    margin: theme.spacing.unit,
    width: 230,
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
});

const theme = createMuiTheme({
  palette: {
    primary: indigo,
    secondary: deepOrange,
  },
  typography: {
    useNextVariants: true,
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class Search3 extends React.Component {
  constructor() {
    super();
    this.state = {
      displayType: 'MULTIPLE',
      numQuestions: 20,
      types: [],
      paperId: undefined,
      loading: '',
      format: '',
    };
  }

  componentDidMount() {
    console.log('component did mount');
    this.ping();
  }

  ping() {
    fetch(`${GetQuestionBaseUrl}/pingcreatequestionpaper`)
    .then(response => {
      console.log('done calling ping');
      return response.json();
    });
  }

  handleChange = event => {
    this.setState({ types: event.target.value });
  };

  handleNumQuestionChange = event => {
    this.setState({ numQuestions: event.target.value });
  };

  handleDisplayTypeChange = event => {
    this.setState({ displayType: event.target.value });
  };

  handleOnlineButton = () => {
    this.setState({loading: 'DYNAMIC', format: 'ONLINE'});
    this.createQuestionPaper()
    .then(
      id => {
        console.log('returned id ' + id);
        this.setState({paperId: id, loading: ''});
        this.props.history.push('/questions/' + id);
      }
    )
    .catch(error => console.log(error));
  }

  handleDownloadButton = () => {
    this.setState({loading: 'DYNAMIC', format: 'PDF'});

    this.createQuestionPaper()
    .then(
      id => this.downloadQuestionPaper(id)
    )
    .then(
      pdfPath => {
        this.setState({loading: '', format: ''});
        window.open(pdfPath);
      }
    )
    .catch(error => console.log(error));
  }

  handleTimesGridDownloadButton = () => {
    this.setState({loading: 'TIMESGRID', format: 'PDF'});

    this.downloadTimesGrid()
    .then(
      pdfPath => {
        this.setState({loading: '', format: ''});
        window.open(pdfPath);
      }
    )
    .catch(error => console.log(error));
  }

  async createQuestionPaper() {
    let paperIdOut = await fetch(`${CreateQuestionBaseUrl}/createquestionpaper`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
          questionPaperType: this.props.questionPaperType || 'WORD',
          displayType: this.state.displayType,
          types: this.state.types,
          numberOfQuestions: this.state.numQuestions,
      })
      })
    .then(response => {
      return response.json();
    })
    .then(data => {
      console.log('question paper id ' + data.questionPaperId);
      return data.questionPaperId;
    })
    .catch(error => console.log(error));
    return paperIdOut;
  }

  async downloadQuestionPaper(paperId) {
    let pdfPath = await fetch(`${DownloadPdfBaseUrl}/pdfexport/${paperId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }
      })
    .then(response => {
      return response.json();
    })
    .then(data => {
      console.log('pdf path ' + data.pdfPath);
      return data.pdfPath;
    })
    .catch(error => console.log(error));
    return pdfPath;
  }

  async downloadTimesGrid() {
    let pdfPath = await fetch(`${DownloadPdfBaseUrl}/timesgrid`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }
      })
    .then(response => {
      return response.json();
    })
    .then(data => {
      console.log('pdf path ' + data.pdfPath);
      return data.pdfPath;
    })
    .catch(error => console.log(error));
    return pdfPath;
  }

  render() {
    const { classes } = this.props;
    const questionPaperType = this.props.questionPaperType || 'WORD';
    const names = {Types};
    const sessionId = sessionStorage.getItem('sessionId');
    return(
      <div>
      <Paper className={classes.paper} elevation={3} >
        {
          this.state.loading==='TIMESGRID'
          ?
            <Button variant="contained" color="secondary" className={classes.button} disabled>
              Loading... <CircularProgress />
            </Button>
          :
          <Button variant="contained" color="primary" className={classes.button} onClick={this.handleTimesGridDownloadButton}>
            Download Times grid questions
          </Button>
        }
      </Paper>
      <br/>
      <Paper className={classes.paper} elevation={3} >
          <Typography variant='body1'>
            Choose one or more question types to practice. If the question type is not selected, the worksheet will contain questions from various types.
          </Typography>
          <br />
          <div>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="select-multiple-checkbox">Question Types</InputLabel>
              <Select
                multiple
                value={this.state.types}
                onChange={this.handleChange}
                input={<Input id="select-multiple-checkbox" />}
                renderValue={selected => selected.join(', ')}
                MenuProps={MenuProps}
                className={classes.selectEmpty}
              >
              <Divider />
                {names.Types[questionPaperType].map(name => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={this.state.types.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="num-questions">Number of Questions</InputLabel>
              <Select
                autoWidth={true}
                value={this.state.numQuestions}
                onChange={this.handleNumQuestionChange}
                input={<Input name="num" id="num-questions" />}
                className={classes.selectEmpty}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>
                  <em>20</em>
                </MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={40}>40</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="display-type">Display Type</InputLabel>
              <Select
                autoWidth={true}
                value={this.state.displayType}
                onChange={this.handleDisplayTypeChange}
                input={<Input name="num" id="display-type" />}
                className={classes.selectEmpty}
              >
                <MenuItem value={'MULTIPLE'}>
                  <em>Multiple questions per page</em>
                </MenuItem>
                <MenuItem value={'SINGLE'}>One question per page</MenuItem>
              </Select>
            </FormControl>
          </div>
            <div>
              <MuiThemeProvider theme={theme}>
              {
                this.state.loading==='DYNAMIC'
                ? this.state.format==='ONLINE'
                  ? <div>
                      <Button variant="contained" color="secondary" className={classes.button}>
                        Loading... <CircularProgress />
                      </Button>
                      <Button variant="contained" color="secondary" disabled className={classes.button}>
                        Download  Worksheet
                      </Button>
                    </div>
                  :  <div>
                      <Button variant="contained" color="secondary" disabled className={classes.button}>
                        Start online test
                      </Button>
                      <Button variant="contained" color="primary" className={classes.button}>
                        Downloading... <CircularProgress />
                      </Button>
                    </div>
                : <div>
                    <Button variant="contained" color="primary" className={classes.button} onClick={this.handleOnlineButton}>
                      Start online test
                    </Button>
                    <Button variant="contained" color="secondary" className={classes.button} onClick={this.handleDownloadButton}>
                      Download Worksheet
                    </Button>
                  </div>
              }
              </MuiThemeProvider>
          </div>
          </Paper>
        </div>
    );
  }
}

Search3.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(Search3));
