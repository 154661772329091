import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Helmet } from 'react-helmet';
import ViewHomework from './ViewHomework';
import Title from './Title';


const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    color: theme.palette.text.secondary,
  },
});

const ViewHomeworkLanding = (props) => {
    const { classes } = props;

    return (
      <div className={classes.root}>
        <Grid container direction="row" justify="center" alignItems="center" spacing={24}>
          <Grid item xs={11}>
            <Paper className={classes.paper}>
              <Title>
                View Homeworks
              </Title>
              <Typography variant='body1'>
                Here you can view the homeworks setup for a date and work on them online.
              </Typography>
              <br />
            </Paper>
          </Grid>
          <Grid item xs={11}>
            <Helmet>
              <meta charSet="utf-8" />
              <title>Eleven Plus: Practice papers, tests and resources</title>
              <meta name="description" content="Prepare for Grammar entrance 11+ elevenplus exams. View Maths homework, practice online, download worksheets, Mock tests, assessments, practice papers and Track progress" />
            </Helmet>
            <Paper className={classes.paper}>
              <Typography variant='title'>
                Select homework
              </Typography>
              <br />
              <ViewHomework source="VIEW" />
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
};

ViewHomeworkLanding.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ViewHomeworkLanding);
