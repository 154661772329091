import React from 'react';
import ReactDOM from 'react-dom';
import { Switch, Route, BrowserRouter as Router} from 'react-router-dom';
import './index.css';
import App from './App';
import SearchLanding from './components/SearchLanding';
import Search3 from './components/Search3';
import Search from './components/SearchBasic';
import ProgressChart from './components/ProgressChart';
import About from './components/About';
import Questions from './components/Questions';
import History from './components/History';
import Results from './components/Results';
import NotFound from './components/NotFound';
import registerServiceWorker from './registerServiceWorker';
import SetupHomework from './components/SetupHomeworkLanding';
import ViewHomework from './components/ViewHomeworkLanding';
import AboutCookie from './components/AboutCookie';
import SiteTemplate from './components/SiteTemplateBasic';
import QuestionImages from './components/QuestionImages';
import CookieConsent from "react-cookie-consent";
import Link from '@material-ui/core/Link';

const routing = (
  <Router>
    <div>
      <SiteTemplate>
        <Switch>
          <Route exact path='/' component={App}/>
          <Route exact path='/search' component={Search}/>
          <Route exact path='/home/search' component={Search3}/>
          <Route exact path='/progress' component={ProgressChart}/>
          <Route exact path='/charts' component={ProgressChart}/>
          <Route exact path='/about' component={About}/>
          <Route exact path='/help/cookie' component={AboutCookie}/>
          <Route exact path='/questions/:id' component={Questions}/>
          <Route path='/questions/:id/results' component={Results}/>
          <Route exact path='/history' component={History}/>
          <Route exact path='/users/history' component={History}/>
          <Route exact path='/users/:id/history' component={History}/>
          <Route exact path='/homework/setup' component={SetupHomework}/>
          <Route exact path='/homework/view' component={ViewHomework}/>
          <Route exact path='/question/test' component={QuestionImages}/>


          <Route component={NotFound}/>
        </Switch>
      </SiteTemplate>

      <CookieConsent
        style={{ background: "#3c4859", textAlign: "center" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
      >
          This website uses cookies to improve user experience.&nbsp;
          <Link href="/help/cookie" color="inherit">
            Click here to read more about cookies
          </Link>
      </CookieConsent>
    </div>
  </Router>
)
ReactDOM.render(
  routing,
  document.getElementById('root')
);
registerServiceWorker();
