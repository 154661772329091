export const BaseUrl = 'https://www.elevenplustime.co.uk';
export const CreateQuestionBaseUrl = 'https://x247imut8e.execute-api.eu-west-2.amazonaws.com/prod';
// export const CreateQuestionBaseUrl = 'https://7xhk50lusl.execute-api.eu-west-2.amazonaws.com/test'
export const GetQuestionBaseUrl = 'https://x247imut8e.execute-api.eu-west-2.amazonaws.com/prod';
// export const GetQuestionBaseUrl = 'https://7xhk50lusl.execute-api.eu-west-2.amazonaws.com/test'
export const MarkQuestionBaseUrl = 'https://ltb7uz7c6j.execute-api.eu-west-2.amazonaws.com/prod';
export const GetResultsBaseUrl = 'https://ltb7uz7c6j.execute-api.eu-west-2.amazonaws.com/prod';
export const DownloadPdfBaseUrl = 'https://20ymrv6hm7.execute-api.eu-west-2.amazonaws.com/prod';
export const ProdLoginBaseUrl = 'https://fihlllbnef.execute-api.eu-west-2.amazonaws.com/prod';
export const LocalLoginBaseUrl = 'https://fihlllbnef.execute-api.eu-west-2.amazonaws.com/local';
export const SessionBaseUrl = 'https://fqdzg55lc2.execute-api.eu-west-2.amazonaws.com/prod';
export const HistoryBaseUrl = 'https://0u5cv1kti5.execute-api.eu-west-2.amazonaws.com/prod';
export const StatsBaseUrl = 'https://syr19ia9k9.execute-api.eu-west-2.amazonaws.com/prod';
export const ImageBaseUrl = 'https://maths-eleven-questions.s3.eu-west-2.amazonaws.com/images/';
export const QuestionMgrBaseUrl = 'http://ec2-63-35-21-213.eu-west-1.compute.amazonaws.com:8937';
// export const QuestionMgrBaseUrl = 'http://localhost:8937';

export const PdfS3BucketUrl = 'https://s3.eu-west-2.amazonaws.com/pdf-papers-maths-eleven.polytech.com/'

//web
export const googleClientId = "535149846546-6du2935o5cfipauv6gsli8souj293cvr.apps.googleusercontent.com";
// export const BaseUrl = 'http://localhost:8080';
// local
// export const googleClientId = "535149846546-fdd7vp13d0fo815ep769vengo4eat4mr.apps.googleusercontent.com";
export const Types = {'CORE': [
                      'Algebra',
                      'Arithmetic',
                      'Fraction',
                      'Percentage',
                    ],
                'WORD': [
                  'Algebra',
                  'Arithmetic',
                  'Clock',
                  'Factor',
                  'Fraction',
                  'Hardcoded',
                  'Percentage',
                  'Others',
                ]
};
