import React from 'react';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import NoteAddIcon from '@material-ui/icons/AddToQueue';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { BaseUrl } from './Constant';
import ErrorTitle from './ErrorTitle';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';

import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import ActiveIcon from '@material-ui/icons/Alarm';
import CancelledIcon from '@material-ui/icons/Block';

import Tooltip from '@material-ui/core/Tooltip';


const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifycontent: 'center',
  },
  fullWidthDiv: {
    width: '100%',
  }
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#357a38',
    },
  },
});

class ViewHomework extends React.Component {
  constructor() {
    super();
    this.state = {
      date: this.getDefaultDate(),
    };
  }

  componentDidMount() {
    this.handleViewHomework();
  }

  getDefaultDate = () => {
    const d = new Date();
    const m = d.getMonth()+1;
    const s = d.getFullYear() + "-" + m + "-" + d.getDate();
    return s;
  }

  timeTaken(runningTimeSecs) {
    if (runningTimeSecs === 0) {
      return '';
    }
    const min = parseInt(runningTimeSecs/60);
    const sec = parseInt(runningTimeSecs%60);
    return (min < 10 ? '0'+min : min) + ' : ' + (sec < 10 ? '0'+sec : sec) ;
  }

  handleDateChange = event => {
    console.log(event.target.value);
    this.setState({ date: event.target.value });
  };

  reviewResult = (answerSheetId) => (event) => {
    this.props.history.push(`/questions/${answerSheetId}/results`);
  }

  cancelQuestionPaper = (paperId) => (event) => {
    // this.props.history.push(`/questions/${answerSheetId}/results`);
    console.log('cancel question paper');
  }

  startQuestionPaper = (paperId) => (event) => {
    this.props.history.push('/questions/' + paperId);
  }

  handleViewHomework = () => {
    fetch(`${BaseUrl}/homework`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
          sessionId: sessionStorage.getItem('sessionId'),
          date: this.state.date,
        })
      })
    .then(response => {
      return response.json();
    })
    .then(response => {
        const newHomework = response.summaries.map(r => {
          return(
            {
                dateTime: r.dateTime,
                questionPaperType: r.questionPaperType,
                questionTypes: r.questionTypes,
                answerSheetId: r.answerSheetId,
                paperId: r.paperId,
                correctAnswers: r.correctAnswers,
                totalQuestions:r.totalQuestions,
                runningTimeSecs: r.runningTimeSecs,
                homeworkStatus: r.homeworkStatus,
            }
          );
        })
        this.setState({...this.state,
                    homework: newHomework,
                  });
    })
    .catch(error => console.log(error));
  }

  render() {
    const { classes, source } = this.props;
    const defaultDate = this.getDefaultDate();
    const sessionId = sessionStorage.getItem('sessionId');

    return (
        <div className={classes.root}>
        { !sessionId ?
          (<div>
              {source === 'SETUP' &&
                <ErrorTitle>
                  Please log in to manage homeworks
                </ErrorTitle>
              }
              {source === 'VIEW' &&
                <ErrorTitle>
                  Please log in to view homeworks
                </ErrorTitle>
              }
          </div>)
          :
          (
          <div className={classes.root}>
            <div className={classes.fullWidthDiv}>
              <FormControl className={classes.formControl} noValidate>
                <TextField
                  id="date"
                  label="Date"
                  type="date"
                  defaultValue={defaultDate}
                  className={classes.textField}
                  onChange={this.handleDateChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
              <Button variant="contained" color="primary" className={classes.button} onClick={this.handleViewHomework}>
                View Homework
                <NoteAddIcon className={classes.rightIcon} />
              </Button>
            </div>
            <br />
            <div className={classes.fullWidthDiv}>
              <Table className={classes.table} style={{ tableLayout: 'auto' }}>
                <colgroup>
                   <col style={{width:'20%'}}/>
                   <col style={{width:'20%'}}/>
                   <col style={{width:'10%'}}/>
                   <col style={{width:'10%'}}/>
                   <col style={{width:'10%'}}/>
                   <col style={{width:'10%'}}/>
                   <col style={{width:'20%'}}/>
                </colgroup>
                <TableHead>
                  <TableRow>
                    <TableCell>Paper Type</TableCell>
                    <TableCell>Question Types</TableCell>
                    <TableCell>Total Questions</TableCell>
                    <TableCell>Correct Answers</TableCell>
                    <TableCell>Time taken</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.homework && this.state.homework.map((result, idx) => {
                    return (
                      <TableRow key={idx}>
                        <TableCell>
                          {result.questionPaperType}
                        </TableCell>
                        <TableCell>
                          {result.questionTypes}
                        </TableCell>
                        <TableCell>
                          {result.totalQuestions}
                        </TableCell>
                        <TableCell>
                          {result.correctAnswers===0?'':result.correctAnswers}
                        </TableCell>
                        <TableCell>
                          {this.timeTaken(result.runningTimeSecs)}
                        </TableCell>
                        <TableCell>
                          {result.homeworkStatus==='COMPLETE' &&
                            <MuiThemeProvider theme={theme}>
                              <Tooltip title="Complete">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  className={classNames(classes.margin, classes.rightIcon)}
                                >
                                  <CheckOutlinedIcon className={classes.rightIcon} />
                                </Button>
                              </Tooltip>
                            </MuiThemeProvider>
                          }
                          {result.homeworkStatus==='ACTIVE' &&
                            <Tooltip title="Active">
                              <Button
                                variant="contained"
                                color="primary"
                                className={classNames(classes.margin, classes.rightIcon)}
                              >
                                <ActiveIcon className={classes.rightIcon} />
                              </Button>
                            </Tooltip>
                          }
                          {result.homeworkStatus==='CANCELLED' &&
                            <Tooltip title="Cancelled">
                              <Button
                                variant="contained"
                                color="secondary"
                                className={classNames(classes.margin, classes.rightIcon)}
                              >
                                <CancelledIcon className={classes.rightIcon} />
                              </Button>
                            </Tooltip>
                          }
                        </TableCell>
                        <TableCell>
                          {result.homeworkStatus==='COMPLETE' && source === 'SETUP' &&
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={this.reviewResult(result.answerSheetId)}
                            >
                              Review Results
                            </Button>
                          }
                          {false && result.homeworkStatus==='ACTIVE' && source === 'SETUP' &&
                            <Tooltip title="Cancel this homework">
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={this.cancelQuestionPaper(result.paperId)}
                              >
                                Cancel
                              </Button>
                            </Tooltip>
                          }
                          {result.homeworkStatus==='ACTIVE' && source === 'VIEW' &&
                            <MuiThemeProvider theme={theme}>
                              <Tooltip title="Start homework">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={this.startQuestionPaper(result.paperId)}
                                >
                                  Start Homework
                                </Button>
                              </Tooltip>
                            </MuiThemeProvider>
                          }
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </div>
        )
      }
      </div>
    );
  }
}

ViewHomework.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(ViewHomework));
