import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { BaseUrl, HistoryBaseUrl } from './Constant';
import ErrorTitle from './ErrorTitle';
import Title from './Title';
import { Helmet } from 'react-helmet';
import Skeleton from '@material-ui/lab/Skeleton';


const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    color: theme.palette.text.secondary,
  },
});

class History extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      history: [],
      loading: false,
    }
  }

  componentDidMount() {
    this.getHistory();
  }

  getHistory() {
    this.setState({loading: true});
    const tokenBlob = new Blob([JSON.stringify({sessionId: sessionStorage.getItem('sessionId')}, null, 2)], {type : 'application/json'});
    const options = {
        method: 'POST',
        body: tokenBlob,
        mode: 'cors',
        cache: 'default'
    };
    fetch(`${HistoryBaseUrl}/history`, options)
    .then(response => {
      return response.json();
    })
    .then(response => {
        const newHistory = response.map(r => {
          return(
            {
                dateTime: r.dateTime,
                viewType: r.questionPaperType,
                questionTypes: r.questionTypes,
                answerSheetId: r.answerSheetId,
                paperId: r.paperId,
                correct: r.correctAnswers,
                total:r.totalQuestions,
                runningTimeSecs: r.runningTimeSecs,
            }
          );
        })
        this.setState({...this.state,
                    history: newHistory,
                  });
    })
    .catch(error => console.log(error))
    .finally(() => this.setState({loading: false}));
  }

  timeTaken(runningTimeSecs) {
    const min = parseInt(runningTimeSecs/60);
    const sec = parseInt(runningTimeSecs%60);
    return (min < 10 ? '0'+min : min) + ' : ' + (sec < 10 ? '0'+sec : sec) ;
  }

  retakeTest = (paperId) => (event) => {
    this.props.history.push(`/questions/${paperId}`);
  }

  reviewResult = (answerSheetId) => (event) => {
    this.props.history.push(`/questions/${answerSheetId}/results`);
  }

  render() {
    const { classes } = this.props;
    const sessionId = sessionStorage.getItem('sessionId');
    return (
      <div className={classes.root}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Eleven Plus: View test history / Track progress</title>
          <meta name="description" content="Prepare for Grammar entrance 11+ elevenplus exams. View your maths test history, track progress and your suggested learning journey" />
        </Helmet>
        { !sessionId ?
          (<div>
            <Paper className={classes.paper} elevation={1}>
              <ErrorTitle>
                Please log in to view your Test History
              </ErrorTitle>
            </Paper>
          </div>)
          :
            this.state.loading
            ? <div>
                <Typography variant='h4' variant="h6" gutterBottom>
                  Please wait, while we load the history...
                </Typography>
                <Skeleton animation="wave" />
              </div>
            : (
              <div>
              <Paper className={classes.paper} elevation={1}>
                <Title>
                  Test History
                </Title>
                <Typography variant="h6" component="h3">
                  Total number of tests: {this.state.history.length}
                </Typography>
              </Paper>
              <br />
              <Paper className={classes.paper} elevation={1}>
              <Table className={classes.table} style={{ tableLayout: 'auto' }}>
                <colgroup>
                   <col style={{width:'20%'}}/>
                   <col style={{width:'20%'}}/>
                   <col style={{width:'5%'}}/>
                   <col style={{width:'5%'}}/>
                   <col style={{width:'10%'}}/>
                   <col style={{width:'20%'}}/>
                   <col style={{width:'20%'}}/>
                </colgroup>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Question Types</TableCell>
                    <TableCell>Total Questions</TableCell>
                    <TableCell>Correct Answers</TableCell>
                    <TableCell>Time Taken</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.history && this.state.history.map((result, idx) => {
                    return (
                      <TableRow key={idx}>
                        <TableCell component="th" scope="row">
                          {result.dateTime}
                        </TableCell>
                        <TableCell>
                          {result.questionTypes}
                        </TableCell>
                        <TableCell>
                          {result.total}
                        </TableCell>
                        <TableCell>
                          {result.correct}
                        </TableCell>
                        <TableCell>
                          {this.timeTaken(result.runningTimeSecs)}
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={this.reviewResult(result.answerSheetId)}
                          >
                            Review Results
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={this.retakeTest(result.paperId)}
                          >
                            Retake Test
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Paper>
            </div>)
        }


    </div>
    );
  }
}

export default withStyles(styles)(History);
