import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import LayersIcon from '@material-ui/icons/Layers';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CookieIcon from '@material-ui/icons/AvTimer';
import Link from '@material-ui/core/Link';


export const mainListItems = (
  <div>
    <a href="/">
    <ListItem button component={Link} to="/">
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Homepage" />
    </ListItem>
    </a>
    <a href="/search">
    <ListItem button component={Link} to="/search">
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Online worksheets" />
    </ListItem>
    </a>
    <a href="/progress">
    <ListItem button  component={Link} to="/progress">
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Progress Charts" />
    </ListItem>
    </a>
    <a href="/users/history">
    <ListItem button  component={Link} to="/users/history">
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Test History" />
    </ListItem>
    </a>
  </div>
);

export const homeworkListItems = (
  <div>
    <ListSubheader inset>Homework</ListSubheader>
    <a href="/homework/setup">
    <ListItem button component={Link} to="/homework/setup">
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="Setup Homework" />
    </ListItem>
    </a>
    <a href="/homework/view">
    <ListItem button component={Link} to="/homework/view">
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="View Homework" />
    </ListItem>
    </a>
  </div>
);

export const linkListItems = (
  <div>
    <ListSubheader inset>Other Links</ListSubheader>
    <a href="/help/cookie">
    <ListItem button component={Link} to="/help/cookie">
      <ListItemIcon>
        <CookieIcon />
      </ListItemIcon>
      <ListItemText primary="Cookie Policy" />
    </ListItem>
    </a>
  </div>
);
