import React from 'react';
import { Helmet } from 'react-helmet';
import Homepage from './components/HomepageBasic';
class App extends React.Component {
  render() {
    return (
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Eleven Plus | Practice papers, tests and resources</title>
          <meta name="description" content="Prepare for Grammar entrance 11+ elevenplus exams. Practice maths tests, download maths worksheets, Mock tests, assessments, practice papers and Track progress" />
        </Helmet>
        <Homepage />
      </div>
    )
  }
}

export default App;
