import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, createMuiTheme } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import deepOrange from '@material-ui/core/colors/deepOrange';
import indigo from '@material-ui/core/colors/indigo';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { BaseUrl, Types } from './Constant';
import TextField from '@material-ui/core/TextField';
import NoteAddIcon from '@material-ui/icons/AddToQueue';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ErrorTitle from './ErrorTitle';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifycontent: 'center',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    minWidth: 300,
  },
  buttonDiv: {
    display: 'flex',
    justifycontent: 'center',
  },
  button: {
    margin: theme.spacing.unit,
    width: 230,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  close: {
    padding: theme.spacing(.5),
  },
  snackbar: {
    marginLeft: theme.spacing.unit,
  }
});

const theme = createMuiTheme({
  palette: {
    primary: indigo,
    secondary: deepOrange,
  },
  typography: {
    useNextVariants: true,
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class CreateHomework extends React.Component {
  constructor() {
    super();
    this.state = {
      displayType: 'MULTIPLE',
      paperType: 'CORE',
      numQuestions: 20,
      types: [],
      paperId: undefined,
      date: this.getDefaultDate(),
      snackbarOpen: false,
      students: [],
      student: null,
    };
  }

  componentDidMount() {
    this.handleGetStudents();
  }

  handleSnackbarClick = () => {
    this.setState({ snackbarOpen: true });
  };

  handleSnackbarClose = (event, reason) => {
    this.setState({ snackbarOpen: false });
  };

  handleTypesChange = event => {
    this.setState({ types: event.target.value });
  };

  handleNumQuestionChange = event => {
    this.setState({ numQuestions: event.target.value });
  };

  handleAssignToChange = event => {
    this.setState({ student: event.target.value });
  };

  handleDisplayTypeChange = event => {
    this.setState({ displayType: event.target.value });
  };

  handleDateChange = event => {
    console.log(event.target.value);
    this.setState({ date: event.target.value });
  };

  handlePaperTypeChange = event => {
    this.setState({ paperType: event.target.value, types: [] });
  };

  handleGetStudents = () => {
    fetch(`${BaseUrl}/auth/user/students`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
          sessionId: sessionStorage.getItem('sessionId'),
        })
      })
    .then(response => {
      return response.json();
    })
    .then(response => {
        this.setState({...this.state,
                    students: response.students,
                  });
    })
    .catch(error => console.log(error));
  }

  handleCreateHomework = () => {
    this.createHomework()
    .then( () => {
      this.handleSnackbarClick();
    })
  }

  async createHomework() {
    await fetch(`${BaseUrl}/homework/create`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              sessionId: sessionStorage.getItem('sessionId'),
              date: this.state.date,
              questionPaperType: this.state.paperType,
              displayType: this.state.displayType,
              types: this.state.types,
              numberOfQuestions: this.state.numQuestions,
              assignTo: this.state.student
          })
          })
        .then(response => {

        })
        .catch(error => console.log(error));
  }

  getDefaultDate = () => {
    const d = new Date();
    const m = d.getMonth()+1;
    const s = d.getFullYear() + "-" + m + "-" + d.getDate();
    return s;
  }

  render() {
    const { classes } = this.props;
    const names = {Types};
    const defaultDate = this.getDefaultDate();
    const sessionId = sessionStorage.getItem('sessionId');

    return(
      <div className={classes.root}>
      { !sessionId ?
        (<div>
            <ErrorTitle>
              Please log in to schedule a homework
            </ErrorTitle>
        </div>)
        :
        (
        <div className={classes.root}>
        <div>
            <FormControl className={classes.formControl} noValidate>
              <TextField
                id="date"
                label="Date"
                type="date"
                defaultValue={defaultDate}
                className={classes.textField}
                onChange={this.handleDateChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="paper-type">Paper Type</InputLabel>
              <Select
                autoWidth={true}
                value={this.state.paperType}
                onChange={this.handlePaperTypeChange}
                input={<Input name="num" id="paper-type" />}
                className={classes.selectEmpty}
              >
                <MenuItem value={'CORE'}>
                  <em>Core concepts</em>
                </MenuItem>
                <MenuItem value={'WORD'}>Word problems</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="select-multiple-checkbox">Question Types</InputLabel>
              <Select
                multiple
                value={this.state.types}
                onChange={this.handleTypesChange}
                input={<Input id="select-multiple-checkbox" />}
                renderValue={selected => selected.join(', ')}
                MenuProps={MenuProps}
                className={classes.selectEmpty}
              >
              <Divider />
                {names.Types[this.state.paperType].map(name => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={this.state.types.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="num-questions">Number of Questions</InputLabel>
              <Select
                autoWidth={true}
                value={this.state.numQuestions}
                onChange={this.handleNumQuestionChange}
                input={<Input name="num" id="num-questions" />}
                className={classes.selectEmpty}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>
                  <em>20</em>
                </MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={40}>40</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </FormControl>

            {this.state.students && this.state.students.length > 0 &&
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="assign-to">Assign homework to</InputLabel>
              <Select
                native
                autoWidth={true}
                value={this.state.student}
                onChange={this.handleAssignToChange}
                inputProps={{
                  name: 'assign-to',
                  id: 'assign-to',
                }}
                className={classes.selectEmpty}
              >
                <option value="" />
                {this.state.students.map(student => (
                  <option value={student}>{student}</option>
                ))}
              </Select>

            </FormControl>
          }
          </div>
          <div>
              <Button variant="contained" color="primary" className={classes.button} onClick={this.handleCreateHomework}>
                Create Homework
                <NoteAddIcon className={classes.rightIcon} />
              </Button>
          </div>
          <div>
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              open={this.state.snackbarOpen}
              className={classes.snackbar}
              autoHideDuration={4000}
              onClose={this.handleSnackbarClose}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}
              message={<span id="message-id">Homework created successfully</span>}
              action={[
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  className={classes.close}
                  onClick={this.handleSnackbarClose}
                >
                  <CloseIcon />
                </IconButton>,
              ]}
          />
          </div>
        </div>  )
      }
      </div>
    );
  }
}

CreateHomework.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(CreateHomework));
