import React from 'react';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CheckOutlined from '@material-ui/icons/CheckOutlined';
import CloseOutlined from '@material-ui/icons/CloseOutlined';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { GetResultsBaseUrl, BaseUrl } from './Constant';
import QuestionTextTitle from './QuestionTextTitle';
import Title from './Title';
import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';
import { ImageBaseUrl } from './Constant';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    color: theme.palette.text.secondary,
  },
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#357a38',
    },
  },
});

class Results extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      results: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.getResults();
  }

  getResults() {
    this.setState({loading: true});
    const paperId = this.props.match.params.id;

    fetch(`${GetResultsBaseUrl}/answersheet/${paperId}`)
    .then(response => {
      return response.json();
    })

    .then(response => {
        const newCorrect = response.correct;
        const newTotal = response.total;
        const newRunningTimeSecs = response.runningTimeSecs;
        var sortedQuestions = response.results;
        sortedQuestions.sort((a,b) => a.questionNum - b.questionNum || a.subQuestion.localeCompare(b.subQuestion));
        const newResults = sortedQuestions.map(r => {
          var sortedQuestion  = r.question;
          sortedQuestion.sort((a,b) => a.rownum - b.rownum)
          var sortedOptions = r.options;
          sortedOptions.sort((a,b) => a.rownum - b.rownum)

          return(
            {
                questionNum: r.questionNum,
                subQuestion: r.subQuestion,
                question: sortedQuestion,
                options: sortedOptions,
                correctAnswer: r.correctAnswer,
                answer:r.answer,
                correct:r.correct,
            }
          );
        })
        this.setState({...this.state,
                    correct: newCorrect,
                    total: newTotal,
                    results: newResults,
                    runningTimeSecs: newRunningTimeSecs,
                  });
    })
    .catch(error => console.log(error))
    .finally(() => this.setState({loading: false}));
  }

  timeTaken() {
    const min = parseInt(this.state.runningTimeSecs/60);
    const sec = parseInt(this.state.runningTimeSecs%60);
    return (min < 10 ? '0'+min : min) + ' : ' + (sec < 10 ? '0'+sec : sec) ;
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        {
          this.state.loading
          ? <div>
              <Typography variant='h4' variant="h6" gutterBottom>
                Please wait, while we load the results...
              </Typography>
              <Skeleton animation="wave" />
            </div>
          : (
              <div>
              <Paper className={classes.paper} elevation={1}>
                <Title>
                  Your score: {this.state.correct} / {this.state.total}
                </Title>
                <Title>
                  Time taken: {this.timeTaken()}
                </Title>
              </Paper>
              <br />

                  {this.state.results && this.state.results.map((row, idx) => {
                    return (
                      <Paper className={classes.paper} elevation={1} key={'rootPaper-'+row.questionNum + row.subQuestion}>
                        <Grid container spacing={2} key={'rootGrid-'+row.questionNum + row.subQuestion}>
                          <Grid item xs={1} container direction="row" spacing={2} key={'slnoGrid-'+row.questionNum + row.subQuestion}>
                            <Typography variant="h3" color="textSecondary" key={'typoQuesNum-'+row.questionNum + row.subQuestion}>
                              {row.questionNum}{row.subQuestion}&nbsp;
                            </Typography>
                          </Grid>
                          <Grid item xs={8} container direction="row" spacing={2} justify="flex-start" alignItems="flex-start" key={'contentGrid-'+row.questionNum + row.subQuestion}>
                            {row.question.map((quesImg, quesIdx) => (
                                <Grid item container xs={12} spacing={2} key={'quesTextGrid-'+row.questionNum + row.subQuestion+'-'+quesIdx}>
                                {
                                  (quesImg.type === "STRING")
                                  ?
                                      <Typography gutterBottom variant="body2">
                                          {quesImg.content}
                                      </Typography>
                                  :
                                      <img className={classes.img1} alt="complex" src={ImageBaseUrl + quesImg.content} width="240" height="180" />
                                }
                                </Grid>
                            ))}
                            {row.options &&
                                <FormControl component="fieldset">
                                  <RadioGroup name={'options-'+row.questionNum + row.subQuestion}>
                                {
                                  row.options.map((optionImg, optionIdx) => {
                                    return (
                                    <div>
                                    <Grid item key={'optionGrid'+row.questionNum + row.subQuestion+'-'+optionIdx}>
                                      <Paper className={classes.paper}>
                                      {
                                        (optionImg.type === "STRING")
                                        ?
                                          <FormControlLabel value={optionImg.optionValue} control={<Radio />} label={optionImg.content} />
                                        :
                                          <div key={'optionImageDiv-'+row.questionNum + row.subQuestion+'-'+optionIdx}>
                                            <FormControlLabel value={optionImg.optionValue} control={<Radio />} label="" />
                                            <img className={classes.img1} src={ImageBaseUrl + optionImg.content} width="80" height="60" />
                                          </div>
                                      }
                                      </Paper>
                                    </Grid>
                                    </div>
                                  );
                                  })}
                            </RadioGroup>
                            </FormControl>
                          }
                          </Grid>
                          <Grid item xs={1} container direction="row" spacing={2} key={'answerValue-'+row.questionNum + row.subQuestion}>
                            <Typography gutterBottom variant="body2">
                                {row.answer}
                            </Typography>
                          </Grid>
                          <Grid item xs={1} container direction="row" spacing={2} key={'correctAnswerValue-'+row.questionNum + row.subQuestion}>
                            <Typography gutterBottom variant="body2">
                                {row.correctAnswer}
                            </Typography>
                          </Grid>
                          <Grid item xs={1} container direction="row" spacing={2} key={'correctAnswerValue-'+row.questionNum + row.subQuestion + 'tick'}>
                            {row.correct===true &&
                              <MuiThemeProvider theme={theme}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  className={classNames(classes.margin, classes.rightIcon)}
                                >
                                  <CheckOutlined className={classes.rightIcon} />
                                </Button>
                              </MuiThemeProvider>
                            }
                            {row.correct===false &&
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  className={classNames(classes.margin, classes.rightIcon)}
                                >
                                  <CloseOutlined className={classes.rightIcon} />
                                </Button>
                            }
                          </Grid>
                        </Grid>
                      </Paper>
                    );
                  })}

            </div>
          )
        }
    </div>
    );
  }
}

export default withStyles(styles)(Results);
