import React from 'react';
import Question from './Question';
import MultiLineQuestion from './MultiLineQuestion';
import { MarkQuestionBaseUrl, GetQuestionBaseUrl, BaseUrl } from './Constant';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';


class Questions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      num: 1,
      displayType: 'MULTIPLE',
      runningTime: 0,
      questions: [],
      results: {},
      loading: true,
      marking: false,
    };
    this.getQuestions = this.getQuestions.bind(this);
    this.submitAnswers = this.submitAnswers.bind(this);
  }

  componentDidMount() {
    this.timer = setInterval(() => {
          const runTime = this.state.runningTime + 1;
          this.setState({ runningTime: runTime });
        }, 1000);
    this.getQuestions();
  }

  componentWillUnmount(){
     clearInterval(this.timer);
   }

  getQuestions() {
    const paperId = this.props.match.params.id;

    fetch(`${GetQuestionBaseUrl}/createquestionpaper/${paperId}`)
    .then(response => {
      return response.json();
    })
    .then(response => {
        const newDisplayType = response.displayType;
        var sortedQuestions = response.questions;
        sortedQuestions.sort((a,b) => a.questionNum - b.questionNum || a.subQuestion.localeCompare(b.subQuestion));
        const newQuestions = sortedQuestions.map(q => {
          var sortedQuestion  = q.question;
          sortedQuestion.sort((a,b) => a.rownum - b.rownum)
          var sortedOptions = q.options;
          sortedOptions && sortedOptions.sort((a,b) => a.rownum - b.rownum)
          return(
            {
                questionNum: q.questionNum,
                subQuestion: q.subQuestion,
                question: sortedQuestion,
                options: sortedOptions,
                answerDatatype: q.answerDatatype,
                answer: ""
            }
          );
        });

        this.setState({...this.state,
                    displayType: newDisplayType,
                    questions: newQuestions,
                  });
    })
    .catch(error => console.log(error))
    .finally(() => this.setState({loading: false}));
  }

  handlePrev = () => {
    const num = this.state.num === 1 ? 1 : this.state.num - 1;
    this.setState({num: num});
  }

  handleNext = () => {
    const num = this.state.num === this.state.questions.length ? this.state.num : this.state.num + 1;
    this.setState({num: num});
  }

  handleAnswerChange = (idx) => (event) => {
    console.log('index ' + idx)
    let newQuestions = this.state.questions.slice();
    const question = newQuestions[idx];
    console.log('questionNum ' + question.questionNum+ " " + question.subQuestion);
    const newQuestion = {
      ...question,
      answer: event.target.value
    };
    newQuestions[idx] = newQuestion;

      this.setState({
        ...this.state,
        questions: newQuestions
      })
  }

  async submitAnswers() {
    const paperId = this.props.match.params.id;
    let answerSheetId = await fetch(`${MarkQuestionBaseUrl}/answersheet/${paperId}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
          answers: this.state.questions,
          runningTimeSecs: this.state.runningTime,
          sessionId: sessionStorage.getItem('sessionId')
      })
      })
      .then(response => {
        return response.json();
      })
      .then(data => {
        this.setState({marking: false});
        return data.answerSheetId;
      })
      .catch(error => console.log(error))
      .finally(() => this.setState({marking: false}));
      return answerSheetId;
  }

  handleSubmitAnswers = () => {
    this.setState({marking: true});
    this.submitAnswers()
    .then(answerSheetId => this.props.history.push(`/questions/${answerSheetId}/results`))
    .catch(error => console.log(error));

  }

  render() {
    const questionNumber = this.state.num;
    const question = this.state.questions[questionNumber-1]
    const answer = question && question.answer
    return (
      <div>
      {
        this.state.loading
        ? <div>
            <Typography variant='h4' variant="h6" gutterBottom>
              Please wait, while we load your questions...
            </Typography>
            <Skeleton animation="wave" />
          </div>
        :
          <div>
            {this.state.questions &&
              this.state.displayType === 'SINGLE' &&
              <Question
              question={question}
              answer={answer}
              count={this.state.questions.length}
              runningTime={this.state.runningTime}
              handlePrev={this.handlePrev}
              handleNext={this.handleNext}
              handleAnswerChange={this.handleAnswerChange}
              submitAnswers={this.handleSubmitAnswers}
              marking={this.state.marking} />
            }

            {this.state.questions &&
              this.state.displayType === 'MULTIPLE' &&
              <MultiLineQuestion
              questions={this.state.questions}
              runningTime={this.state.runningTime}
              handleAnswerChange={this.handleAnswerChange}
              submitAnswers={this.handleSubmitAnswers}
              marking={this.state.marking} />
            }
          </div>
      }
      </div>
    );
  }
}

export default Questions;
