import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import searchImg from '../static/images/search-img.png';
import historyImg from '../static/images/test-history.png';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    color: theme.palette.text.secondary,
  },
  centerTypo: {
    textAlign: 'center',
  },
  demo: {
   backgroundColor: theme.palette.background.paper,
 },
});

const About = ({ classes }) => {
    return(
      <div className={classes.root}>
        <Paper className={classes.paper} elevation={1}>
          <Typography variant="headline">
            We believe Children can achieve great scores if we can track and encourage their progress.
          </Typography>
          <br />
          <Typography variant="body1">
          Children use worksheets or past 11+ papers to practice/prepare for the Grammar entrance exams. Although this approach is useful, a major drawback is the lack of feedback or a way to track progress.
          There is no way to identify the areas that needs improvement or areas that the Child has improved over the past weeks/months. Net result, Child gets discouraged due to questions in the worksheets that have not been taught
          and Parents gets stressed by the number of incorrect answers.
          </Typography>
        </Paper>
        <br />
        <Paper className={classes.paper} elevation={1}>
          <Typography variant="headline">
            Our Mission is to provide tools that helps to unlock Childs'' potential while also encouraging their progress.
          </Typography>
        </Paper>
        <br />
        <Paper className={classes.paper} elevation={1}>
          <Typography variant="title">
            <a href="/search">Maths Testing Tool</a>
          </Typography>
          <img src={searchImg} alt="" />
          <Grid container spacing={16}>
            <Grid item xs={6} md={3}>
              <Typography variant="subtitle">
                Practice one or more  <br />
              </Typography>
              <Typography variant="body1">
                - Arithmetic <br />
                - Algebra <br />
                - Clock <br />
                - Factor <br />
                - Fraction <br />
                - Geometry <br />
                - Money <br />
                - Word Arithmetic <br />
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="subtitle">
                Choose number of questions
              </Typography>
              <Typography variant="body1">
                - 10 <br />
                - 20 <br />
                - 30 <br />
                - 40 <br />
                - 50 <br />
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="subtitle">
                Practice online
              </Typography>
              <Typography variant="body1">
                - Answer Questions online <br />
                - Track Time<br />
                - Check Answers<br />
                - Find score<br />
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="subtitle">
                Download PDF
              </Typography>
              <Typography variant="body1">
                - Print PDF Questions<br />
                - Print PDF Answers<br />
                - Practice offline  <br />
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        <br />
        <Paper className={classes.paper} elevation={1}>
          <Typography variant="title">
            <a href="/users/history">Test History Tool</a> (only available for logged in Users)
          </Typography>
          <br />
          <img src={historyImg} alt="" />
          <br />
          <Grid container spacing={16}>
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle">
                View Details
              </Typography>
              <Typography variant="body1">
                - View practice dates <br />
                - View past scores <br />
                - Track progress <br />
                - Track time taken <br />
              </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle">
                Review Results
              </Typography>
              <Typography variant="body1">
                - Review results <br />
                - Check progress <br />
                - View questions <br />
                - View answers <br />
              </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle">
                Retake Test
              </Typography>
              <Typography variant="body1">
                - Practice the same questions <br />
                - Check improvement <br />
                - Track time taken <br />
                - Revise concepts<br />
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
};

export default withStyles(styles)(About);
