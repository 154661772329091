import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { StatsBaseUrl } from './Constant';
import ErrorTitle from './ErrorTitle';
import Title from './Title';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';

import {
  ComposedChart, LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend
} from 'recharts';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    color: theme.palette.text.secondary,
  },

});

class ProgressChart extends React.Component {
  constructor() {
    super();
    this.state = {
        loading: false
    };
  }

  componentDidMount() {
    this.setState({loading: true});
    this.handleGetStats();
  }

  handleGetStats = () => {
    fetch(`${StatsBaseUrl}/getstats`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
          sessionId: sessionStorage.getItem('sessionId'),
          statsDuration: 'DAY',
          period: 7,
          date: this.state.date,
        })
      })
    .then(response => {
      return response.json();
    })
    .then(response => {
        const newStats = response.summaryStats.map(r => {
          return(
            {
                name: r.name,
                incorrect: r.total - r.correct,
                correct: r.correct,
                percentage: r.percentage
            }
          );
        })

        const newQuestionTypeStats = response.questionTypeStats.map(r => {
          return(
            {
                name: r.questionType.charAt(0).toUpperCase() + r.questionType.slice(1).toLowerCase(),
                incorrect: r.totalQuestions - r.correctAnswers,
                correct: r.correctAnswers,
                percentage: r.percentage
            }
          );
        })

        this.setState({...this.state,
                    stats: newStats,
                    questionTypeStats: newQuestionTypeStats,
                  });
    })
    .catch(error => console.log(error))
    .finally(() => this.setState({loading: false}));
  }

  render() {
    const { classes } = this.props;
    const sessionId = sessionStorage.getItem('sessionId');

    return (
      <div className={classes.root}>
      { !sessionId ?
        (<div>
            <Paper className={classes.paper} elevation={1}>
              <ErrorTitle>
                  Please log in to view progress
              </ErrorTitle>
            </Paper>
        </div>)
        :
        this.state.loading
        ? <div>
            <Typography variant='h4' variant="h6" gutterBottom>
              Please wait, while we load the charts...
            </Typography>
            <Skeleton animation="wave" />
          </div>
        :
        (
         <div>
          <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Title>
                  Progress Charts
                </Title>
                <Typography variant='body1'>

                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
              <Grid item>
                <Paper className={classes.paper}>
                  <Typography variant='body2'>
                    % Marks in the past week
                  </Typography>
                  <LineChart
                    width={500}
                    height={300}
                    data={this.state.stats}
                    margin={{
                      top: 5, right: 30, left: 20, bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="percentage" stroke="#8884d8" activeDot={{ r: 8 }} />
                  </LineChart>
                </Paper>
              </Grid>
              <Grid item>
                <Paper className={classes.paper}>
                  <Typography variant='body2'>
                    Daily marks in the past week
                  </Typography>
                  <BarChart
                          width={500}
                          height={300}
                          data={this.state.stats}
                          margin={{
                            top: 20, right: 30, left: 20, bottom: 5,
                          }}
                        >
                    <CartesianGrid stroke="#f5f5f5" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="correct" stackId="a" fill="#8884d8" />
                            <Bar dataKey="incorrect" stackId="a" fill="#ff0000" />
                            {/* <Scatter dataKey="cnt" fill="red" /> */}
                  </BarChart>
                </Paper>
              </Grid>
            </Grid>
            <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                <Grid item xs={12}>
                  <Paper className={classes.paper}>
                    <Typography variant='body2'>
                      Question type marks in the past week
                    </Typography>
                    <ComposedChart
                      width={1300}
                      height={300}
                      data={this.state.questionTypeStats}
                      margin={{
                        top: 5, right: 30, left: 20, bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="correct" stackId="a" fill="#8884d8" />
                      <Bar dataKey="incorrect" stackId="a" fill="#ff0000" />
                      <Line type="monotone" dataKey="percentage" stroke="#8884d8" activeDot={{ r: 8 }} />
                    </ComposedChart>
                  </Paper>
              </Grid>
            </Grid>
          </div>
        )
      }
      </div>
    );
  }
}

ProgressChart.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProgressChart);
