import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Helmet } from 'react-helmet';
import CreateHomework from './CreateHomework';
import ViewHomework from './ViewHomework';
import ErrorTitle from './ErrorTitle';
import Title from './Title';


const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    color: theme.palette.text.secondary,
  },
});

const SetupHomeworkLanding = (props) => {
    const { classes } = props;

    return (
      <div className={classes.root}>
        <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
          <Grid item xs={11}>
            <Paper className={classes.paper}>
              <Title>
                Setup Homeworks
              </Title>
              <Typography variant='body1'>
                Here you can schedule a new Homework or edit/cancel a Homework
              </Typography>
              <br />
            </Paper>
          </Grid>
          <Grid item xs={11}>
            <Helmet>
              <meta charSet="utf-8" />
              <title>Eleven Plus: Practice papers, tests and resources</title>
              <meta name="description" content="Prepare for Grammar entrance 11+ elevenplus exams. Setup maths homework, practice maths test, download worksheets, Mock tests, assessments, practice papers and Track progress" />
            </Helmet>
            <Paper className={classes.paper}>
              <Typography variant='h6'>
                Schedule new Homework
              </Typography>
              <br />
              <CreateHomework questionPaperType="WORD" />
            </Paper>
          </Grid>
          <Grid item xs={11}>
            <Helmet>
              <meta charSet="utf-8" />
              <title>Eleven Plus: Practice papers, tests and resources</title>
              <meta name="description" content="Prepare for Grammar entrance 11+ elevenplus exams. View maths homework, practice online, download worksheets, Mock tests, assessments, practice papers and Track progress" />
            </Helmet>
            <Paper className={classes.paper}>
              <Typography variant='h6'>
                Manage existing Homeworks
              </Typography>
              <br />
              <ViewHomework source="SETUP" />
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
};

SetupHomeworkLanding.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SetupHomeworkLanding);
