import React, { Component } from 'react';
import { GoogleLogin } from 'react-google-login';
import GoogleButton from 'react-google-button';
import { googleClientId, BaseUrl, ProdLoginBaseUrl, LocalLoginBaseUrl, SessionBaseUrl } from './Constant';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (theme) => ({

});

const Login = ({ classes, loginSuccess, loginFailed }) => {
  const [auth, setAuth] = React.useState(false);

  const googleResponse = (response) => {
    setAuth(true);
    const tokenBlob = new Blob([JSON.stringify({tokenId: response.tokenId}, null, 2)], {type : 'application/json'});
    const options = {
        method: 'POST',
        body: tokenBlob,
        mode: 'cors',
        cache: 'default'
    };
    // fetch(`${LocalLoginBaseUrl}/auth`, options).then(r => {
    fetch(`${ProdLoginBaseUrl}/auth`, options).then(r => {
        const token = r.headers.get('x-auth-token');

        r.json().then(user => {
            if (token) {
                sessionStorage.setItem('sessionId', token);
                loginSuccess(user);
            } else {
              errorLoggingIn(r);
            }
        });
    })
    .catch(error => loginFailed())
    .finally(()=> setAuth(false));
  }

  const errorLoggingIn = (response) => {
    console.log(response);
    loginFailed();
  }

  const loggingIn = () => {
    setAuth(true);
  }

  const loggingInDone = () => {
    setAuth(false);
  }

  return (
      <div>
      {
        auth
        ?
        (
          <div>
            <Button variant="contained" color="secondary" enabled="false" className={classes.button}>
              Authenticating... <CircularProgress />
            </Button>
          </div>
        )
        :
        (
          <GoogleLogin
              clientId={googleClientId}
              render={renderProps => (
                <GoogleButton onClick={renderProps.onClick} />
              )}
              onSuccess={googleResponse}
              onFailure={errorLoggingIn}
          />
        )
      }
      </div>
  );
}

export default withStyles(styles)(Login);
