import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { mainListItems, homeworkListItems, linkListItems } from './listItems';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Login from './Login';
import logoImg from '../static/images/logo3.png';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    align: 'center'
  },
  card: {
    minWidth: 300,
    align: 'center',
    justifycontent: 'center',
    alignItems: 'center',
  },
  pos: {
    marginBottom: 12,
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  }
}));

const LoginLanding = ({ classes, loginSuccess, loginFailed }) => {

  return (
    <div className={classes.root}>
      <Grid
       container
       spacing={0}
       direction="column"
       alignItems="center"
       justify="center"
      >
        <Grid item xs={12}>
          <Card className={classes.card}>
            <CardContent>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                Welcome back.
                <br />
              </Typography>
              <Typography variant="body2" component="p">
                Login using the following option:
                <br />
              </Typography>
            </CardContent>
            <CardActions>
              <Login
                loginSuccess = {loginSuccess}
                loginFailed = {loginFailed}/>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

LoginLanding.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LoginLanding);
