import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import Search3 from './Search3';
import Title from './Title';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Helmet } from 'react-helmet';



const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    color: theme.palette.text.secondary,
  },

});

const SearchLanding = ({ classes, props }) => {
  return (
    <div className={classes.root}>
      <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
        <Grid item xs={11}>
          <Paper className={classes.paper}>
            <Title>
              Online worksheets
            </Title>
            <Typography variant='body1'>
              Here you can choose to practice either core concepts or advance your knowledge with word problems. Both options allow you to choose one or more topics to practice.
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={11}>

        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>Practice Concepts</Typography>
            <Helmet>
              <meta charSet="utf-8" />
              <title>ElevenPlus: Practice papers, maths tests and resources</title>
              <meta name="description" content="Prepare for Grammar entrance 11+ elevenplus exams. Practice online, download worksheets, Mock tests, assessments, practice papers and Track progress" />
            </Helmet>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Typography variant='body2'>
                    Practice core maths concepts
                  </Typography>
                  <Typography variant='body1'>
                    Here the questions are designed to improve the core skills required to solve maths problems. The questions are mostly in the form of equations, to help Children to get a good understanding of the fundamentals.
                    Mastery of these concepts are essential to excel in word problems.
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Search3 questionPaperType="CORE"/>
                </Paper>
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>Practice Word Problems</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Typography variant='body2'>
                    Practice word problem questions
                  </Typography>
                  <Typography variant='body1'>
                    Here the questions are designed to solve word problems using the core concepts. The questions are mostly in the form of real world examples and mastery of core concepts will allow Children to solve these questions with ease.
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Search3 questionPaperType="WORD"/>
                </Paper>
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        </Grid>
      </Grid>
    </div>
  );
}

SearchLanding.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SearchLanding);
