import React from 'react';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn';
import deepOrange from '@material-ui/core/colors/deepOrange';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import QuestionTextTitle from './QuestionTextTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import clsx from 'clsx';
import { ImageBaseUrl } from './Constant';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
  },
  card: {
    minWidth: 275,
  },
  title: {
    marginBottom: 16,
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  buttonDiv: {
    display: 'flex',
    justifycontent: 'flex-start',
  },
  button: {
    margin: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: 5
  },
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#357a38',
    },
    secondary: deepOrange,
  },
  typography: {
    useNextVariants: true,
  },
});

const MultiLineQuestion = ({ classes, questions, runningTime, handleAnswerChange, submitAnswers, marking }) => {
  const min = parseInt(runningTime/60);
  const sec = parseInt(runningTime%60);
  const timer = (min < 10 ? '0'+min : min) + ':' + (sec < 10 ? '0'+sec : sec);
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {!questions && <CircularProgress className={classes.progress} />}
        {questions &&
        <div className={classes.buttonDiv}>
          <div className={classes.grow} />
          <MuiThemeProvider theme={theme}>
            {
              marking
              ? <Button variant="contained" color="secondary"
                              className={classes.button}>
                  Marking Answers... <CircularProgress />
                </Button>
              : <Button variant="contained" color="primary"
                              className={classes.button}
                              onClick={submitAnswers}>
                  Check Answers: {timer} -
                  <AssignmentTurnedIn className={classes.rightIcon}></AssignmentTurnedIn>
                </Button>
            }
            </MuiThemeProvider>
        </div>
        }
      </Paper>

      {questions && questions.map((row, idx) => {
        return (
          <Paper className={classes.paper} elevation={1} key={'rootPaper-'+row.questionNum + row.subQuestion}>
            <Grid container spacing={2} key={'rootGrid-'+row.questionNum + row.subQuestion}>
              <Grid item xs={1} container direction="row" spacing={2} key={'slnoGrid-'+row.questionNum + row.subQuestion}>
                <Typography variant="h3" color="textSecondary" key={'typoQuesNum-'+row.questionNum + row.subQuestion}>
                  {row.questionNum}{row.subQuestion}&nbsp;
                </Typography>
              </Grid>
              <Grid item xs={10} container direction="row" spacing={2} justify="flex-start" alignItems="flex-start" key={'contentGrid-'+row.questionNum + row.subQuestion}>
                {row.question.map((quesImg, quesIdx) => (
                    <Grid item container xs={12} spacing={2} key={'quesTextGrid-'+row.questionNum + row.subQuestion+'-'+quesIdx}>
                    {
                      (quesImg.type === "STRING")
                      ?
                          <Typography gutterBottom variant="body2">
                              {quesImg.content}
                          </Typography>
                      :
                          <img className={classes.img1} alt="complex" src={ImageBaseUrl + quesImg.content} width="240" height="180" />
                    }
                    </Grid>
                ))}
                {row.options &&
                    <FormControl component="fieldset">
                      <RadioGroup onChange={handleAnswerChange(idx)} name={'options-'+row.questionNum}>
                    {
                      row.options.map((optionImg, optionIdx) => {
                        return (
                        <div>
                        <Grid item key={'optionGrid'+row.questionNum + row.subQuestion+'-'+optionIdx}>
                          <Paper className={classes.paper}>
                          {
                            (optionImg.type === "STRING")
                            ?
                              <FormControlLabel value={optionImg.optionValue} control={<Radio />} label={optionImg.content} />
                            :
                              <div key={'optionImageDiv-'+row.questionNum + row.subQuestion+'-'+optionIdx}>
                                <FormControlLabel value={optionImg.optionValue} control={<Radio />} label="" />
                                <img className={classes.img1} src={ImageBaseUrl + optionImg.content} width="80" height="60" />
                              </div>
                          }
                          </Paper>
                        </Grid>
                        </div>
                      );
                      })}
                </RadioGroup>
                </FormControl>
              }
              </Grid>
              <Grid item xs={1} container direction="row" spacing={2} key={'answerGrid-'+row.questionNum + row.subQuestion}>
                <TextField
                  autoFocus
                  name={'answer-'+row.questionNum + row.subQuestion}
                  label="Answer"
                  className={clsx(classes.textField, classes.dense)}
                  margin="dense"
                  autoComplete='off'
                  variant="outlined"
                  value={row.answer}
                  onChange={handleAnswerChange(idx)}
                  disabled={row.answerDatatype === 'OPTION' ? true : false}
                />
              </Grid>
            </Grid>
          </Paper>
        );
      })}

    </div>
  );
}

MultiLineQuestion.propTypes = {
  classes: PropTypes.object.isRequired,
  questions: PropTypes.array,
};

export default withStyles(styles)(MultiLineQuestion);
