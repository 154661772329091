import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { BaseUrl } from './Constant';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import { ImageBaseUrl, QuestionMgrBaseUrl } from './Constant';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';

const styles = theme => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: 5
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
});

class QuestionImages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      questions: [],
      sources: [],
      selectedSource: ""
    };
    this.getQuestions = this.getQuestions.bind(this);
  }

  componentDidMount() {
    this.getSources();
  }

  getSources() {
    const paperId = this.props.match.params.id;
    fetch(`${QuestionMgrBaseUrl}/sources`)
    .then(response => {
      return response.json();
    })
    .then(response => {
      var sortedSources = response.sources.sort();
      this.setState({...this.state,
                    sources: sortedSources,
                  });
    })
    .catch(error => console.log(error));
  }

  getQuestions(paperId) {
    // const paperId = this.props.match.params.id;
    fetch(`${QuestionMgrBaseUrl}/questions/${paperId}`)
    .then(response => {
      return response.json();
    })
    .then(response => {
      var sortedQuestions = response.questions;
      sortedQuestions.sort((a,b) => a.questionNum - b.questionNum || a.subQuestion.localeCompare(b.subQuestion));
      const newQuestions = sortedQuestions.map(q => {
        var sortedQuestion  = q.question;
        sortedQuestion.sort((a,b) => a.rownum - b.rownum)
        var sortedOptions = q.options;
        sortedOptions.sort((a,b) => a.rownum - b.rownum)
        return(
          {
              questionNum: q.questionNum,
              subQuestion: q.subQuestion,
              question: sortedQuestion,
              options: sortedOptions,
              answerDatatype: q.answerDatatype,
              correctAnswer: q.answer,
              answer: ""
          }
        );
      })

        this.setState({...this.state,
                    questions: newQuestions,
                  });
    })
    .catch(error => console.log(error));
  }

  handleAnswerChange = (idx) => (event) => {
    let newQuestions = this.state.questions.slice();
    const question = newQuestions[idx];
    const newQuestion = {
      ...question,
      answer: event.target.value
    };
    newQuestions[idx] = newQuestion;

    this.setState({
      ...this.state,
      questions: newQuestions
    })

  }

  handleSourceChange = event => {
    console.log('source change-'+event.target.value);
    this.setState({ selectedSource: event.target.value });
    this.getQuestions(event.target.value);
  };

  render() {
    const {classes} = this.props;
    return (
      <div className={classes.root} key='rootDiv'>
      <Paper className={classes.paper}>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">Worksheet name</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={this.state.selectedSource}
            onChange={this.handleSourceChange}
          >
            {this.state.sources.map(source => {
              return <MenuItem value={source}>{source}</MenuItem>
            })};
          </Select>
        </FormControl>
      </Paper>
      {this.state.questions && this.state.questions.map(row => (
        <Paper className={classes.paper} elevation={1} key={'rootPaper-'+row.questionNum}>
          <Grid container spacing={2} key={'rootGrid-'+row.questionNum}>
            <Grid item xs={1} container direction="row" spacing={2} key={'slnoGrid-'+row.questionNum}>
              <Typography variant="h3" color="textSecondary" key={'typoQuesNum-'+row.questionNum}>
                {row.questionNum}{row.subQuestion}&nbsp;
              </Typography>
            </Grid>
            <Grid item xs={10} container direction="row" spacing={2} justify="flex-start" alignItems="flex-start" key={'contentGrid-'+row.questionNum}>
              {row.question.map((quesImg, quesIdx) => (
                  <Grid item container xs={12} spacing={2} key={'quesTextGrid-'+row.questionNum+'-'+quesIdx}>
                  {
                    (quesImg.type === "STRING")
                    ?
                        <Typography gutterBottom variant="body2">
                            {quesImg.content}
                        </Typography>
                    :
                        <img className={classes.img1} alt="complex" src={ImageBaseUrl + quesImg.content} width="240" height="180" />
                  }
                  </Grid>
              ))}
              {row.options &&
                  <FormControl component="fieldset">
                    <RadioGroup onChange={this.handleAnswerChange(row.questionNum-1)} name={'options-'+row.questionNum}>
                  {
                    row.options.map((optionImg, optionIdx) => {
                      return (
                      <div>
                      <Grid item key={'optionGrid'+row.questionNum+'-'+optionIdx}>
                        <Paper className={classes.paper}>
                        {
                          (optionImg.type === "STRING")
                          ?
                            <FormControlLabel value={optionImg.optionValue} control={<Radio />} label={optionImg.content} />
                          :
                            <div key={'optionImageDiv-'+row.questionNum+'-'+optionIdx}>
                              <FormControlLabel value={optionImg.optionValue} control={<Radio />} label="" />
                              <img className={classes.img1} src={ImageBaseUrl + optionImg.content} width="80" height="60" />
                            </div>
                        }
                        </Paper>
                      </Grid>
                      </div>
                    );
                    })}
              </RadioGroup>
              </FormControl>
            }
            </Grid>
            <Grid item xs={1} container direction="row" spacing={2} key={'answerGrid-'+row.questionNum}>
              <TextField
                autoFocus
                name={'answer-'+row.questionNum}
                label="Answer"
                className={clsx(classes.textField, classes.dense)}
                margin="dense"
                autoComplete='off'
                variant="outlined"
                value={row.answer}
                onChange={this.handleAnswerChange(row.questionNum-1)}
                disabled={row.answerDatatype === 'OPTION' ? true : false}
              />
            </Grid>
          </Grid>
        </Paper>
      ))}
      </div>
    );
  }
}

export default withStyles(styles)(QuestionImages);
