import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Search from './SearchBasic';
import Title from './Title';
import HomepageTestHistory from './HomepageTestHistory'
import HomepageProgressChart from './HomepageProgressChart'

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  card: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});


const HomepageBasic = (props) => {
    const { classes } = props;
    return (
            <div>
              <Grid container spacing={3}>
                {/* Chart */}
                <Grid item xs={12}>
                  <Paper className={classes.paper} elevation={3}>
                    <Title>
                      Online worksheets
                    </Title>
                    <Search questionPaperType="WORD" />
                  </Paper>
                </Grid>
              </Grid>
            </div>
          );
};

HomepageBasic.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HomepageBasic);
