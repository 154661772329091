import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import deepOrange from '@material-ui/core/colors/deepOrange';
import indigo from '@material-ui/core/colors/indigo';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { BaseUrl, Types } from './Constant';
import Typography from '@material-ui/core/Typography';


const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifycontent: 'center',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    minWidth: 300,
  },
  buttonDiv: {
    display: 'flex',
    justifycontent: 'center',
  },
  button: {
    margin: theme.spacing.unit,
    width: 230,
  },
});

const theme = createMuiTheme({
  palette: {
    primary: indigo,
    secondary: deepOrange,
  },
  typography: {
    useNextVariants: true,
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class Search3 extends React.Component {
  constructor() {
    super();
    this.state = {
      displayType: 'MULTIPLE',
      numQuestions: 20,
      types: [],
      paperId: undefined,
    };
    console.log('in constructor');
  }

  handleChange = event => {
    this.setState({ types: event.target.value });
  };

  handleNumQuestionChange = event => {
    this.setState({ numQuestions: event.target.value });
  };

  handleDisplayTypeChange = event => {
    this.setState({ displayType: event.target.value });
  };

  handleOnlineButton = () => {
    this.createQuestionPaper()
    .then(
      id => {
        this.setState({paperId: id});
        this.props.history.push('/questions/' + id);
      }
    )
    .catch(error => console.log(error));
  }

  handleDownloadButton = () => {
    this.createQuestionPaper()
    .then(
      id => window.open(BaseUrl+'/maths/questions/'+id+'/questionPaperDownload.pdf')
    )
    .catch(error => console.log(error));
  }

  async createQuestionPaper() {
    let paperIdOut = await fetch(`${BaseUrl}/maths/questions/create`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
          questionPaperType: this.props.questionPaperType || 'WORD',
          displayType: this.state.displayType,
          types: this.state.types,
          numberOfQuestions: this.state.numQuestions,
      })
      })
    .then(response => {
      return response.json();
    })
    .then(data => {
      return data.questionPaperId;
    })
    .catch(error => console.log(error));
    return paperIdOut;
  }

  render() {
    const { classes } = this.props;
    const questionPaperType = this.props.questionPaperType || 'WORD';
    const names = {Types};
    const sessionId = sessionStorage.getItem('sessionId');
    return(
      <div>
          <Typography variant='body1'>
            Choose one or more question types to practice. If the question type is not selected, the worksheet will contain questions from various types.
          </Typography>
          <br />
          <div>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="select-multiple-checkbox">Question Types</InputLabel>
              <Select
                multiple
                value={this.state.types}
                onChange={this.handleChange}
                input={<Input id="select-multiple-checkbox" />}
                renderValue={selected => selected.join(', ')}
                MenuProps={MenuProps}
                className={classes.selectEmpty}
              >
              <Divider />
                {names.Types[questionPaperType].map(name => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={this.state.types.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="num-questions">Number of Questions</InputLabel>
              <Select
                autoWidth={true}
                value={this.state.numQuestions}
                onChange={this.handleNumQuestionChange}
                input={<Input name="num" id="num-questions" />}
                className={classes.selectEmpty}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>
                  <em>20</em>
                </MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={40}>40</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="display-type">Display Type</InputLabel>
              <Select
                autoWidth={true}
                value={this.state.displayType}
                onChange={this.handleDisplayTypeChange}
                input={<Input name="num" id="display-type" />}
                className={classes.selectEmpty}
              >
                <MenuItem value={'MULTIPLE'}>
                  <em>Multiple questions per page</em>
                </MenuItem>
                <MenuItem value={'SINGLE'}>One question per page</MenuItem>
              </Select>
            </FormControl>
          </div>
          { !sessionId ?
            (<div>
              <MuiThemeProvider theme={theme}>
                <Button variant="contained" color="primary" disabled className={classes.button}>
                  Start online test
                </Button>
                <Button variant="contained" color="secondary" disabled className={classes.button}>
                  Download PDF worksheet
                </Button>
              </MuiThemeProvider>
              <br />
              <Typography component="h4" variant="h6" color="secondary" gutterBottom>
                  Please sign in to access online worksheet
              </Typography>
            </div>)
            :
            (<div>
            <MuiThemeProvider theme={theme}>
              <Button variant="contained" color="primary" className={classes.button} onClick={this.handleOnlineButton}>
                Start online test
              </Button>
              <Button variant="contained" color="secondary" className={classes.button} onClick={this.handleDownloadButton}>
                Download PDF worksheet
              </Button>
            </MuiThemeProvider>
          </div>
        )
      }
        </div>
    );
  }
}

export default withStyles(styles)(withRouter(Search3));
